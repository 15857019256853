/* eslint-disable no-unused-vars */
// import { API } from "aws-amplify";
// import { sendMessage } from '../util';

export const CARD_PRESENT_TYPE = 'pt-static:card-present';

const createString = `mutation CreateCardPresent($payor: PayorInput,
                                                 $send_receipt: Boolean,
                                                 $reference: String,
                                                 $recurring_id: String,
                                                 $receipt_description: String,
                                                 $payor_id: String,
                                                 $payment_parameters_name: String,
                                                 $metadata: AWSJSON,
                                                 $merchant_uid: String!,
                                                 $invoice_id: String,
                                                 $fee_mode: FeeMode = MERCHANT_FEE,
                                                 $fee: Int,
                                                 $device_id: String!,
                                                 $currency: String = "USD",
                                                 $amount: Int!,
                                                 $account_code: String
                                                 $timezone: String) {
  createCardPresentPayment(amount: $amount,
                           device_id: $device_id,
                           merchant_uid: $merchant_uid,
                           account_code: $account_code,
                           currency: $currency,
                           fee: $fee,
                           fee_mode: $fee_mode,
                           invoice_id: $invoice_id,
                           metadata: $metadata,
                           payment_parameters_name: $payment_parameters_name,
                           payor: $payor,
                           payor_id: $payor_id,
                           receipt_description: $receipt_description,
                           recurring_id: $recurring_id,
                           reference: $reference,
                           send_receipt: $send_receipt
                           timezone: $timezone)
}`;

// export const activateCardPresentDevice = (variables, origin) => {
//     API.graphql({query: createString, variables})
//         .then((response) => {
//             const receiptNumber = response.data.createCardPresentPayment
//             subscribeToCardPresent(receiptNumber, origin)
//             const message = {
//                 type: CARD_PRESENT_TYPE,
//                 body: {
//                     status: "ACTIVATED",
//                     details: receiptNumber
//                 }
//             }
//             sendMessage(message, origin)
//         })
//         .catch((error) => {
//             console.log(error)
//             let messages = parseAppSyncErrors(error)
//             let message = {
//                 type: `pt-static:error`,
//                 error: `ACTIVATION_ERROR: ${messages}`,
//                 field: 'card-present'
//             }
//             sendMessage(message, origin)
//         })
// }

const subscriptionString = `subscription CardPresentComplete($transaction_id: String!) {
  createCardPresentCompletion(transaction_id: $transaction_id) {
    amount
    card_brand
    created_at
    currency
    failure_reason
    last_four
    service_fee
    status
    transaction_id
  }
}`;

// const subscribeToCardPresent = (transaction_id, origin) => {
//     API.graphql({query: subscriptionString, variables: { transaction_id }})
//         .subscribe({
//             next: (response) => {
//                 const message = {
//                     type: CARD_PRESENT_TYPE,
//                     body: {
//                         status: "COMPLETE",
//                         details: response.value.data.createCardPresentCompletion
//                     }
//                 }
//                 sendMessage(message, origin)
//             },
//             error: (error) => {
//                 console.log(error)
//                 let messages = parseAppSyncErrors(error.error)
//                 let message = {
//                     type: `pt-static:error`,
//                     error: `COMPLETION_ERROR: ${messages}`,
//                     field: 'card-present'
//                 }
//                 sendMessage(message, origin)
//             }
//         })
// }

export const parseToken = token => {
  const json = decodeURI(token);
  const decodedJson = window.atob(json);
  return JSON.parse(decodedJson);
};

export const convertToPayorInput = payor => {
  if (!payor) return null;
  let address = payor?.personal_address;
  let fullName = `${payor.first_name} ${payor.last_name}`.trim();
  return {
    address_line1: address?.line1,
    address_line2: address?.line2,
    city: address?.city,
    country: address?.country,
    email: payor.email,
    full_name: fullName,
    phone: payor.phone,
    region: address?.region,
    postal_code: address?.postal_code,
  };
};

const parseAppSyncErrors = response => {
  const errors = response.errors;
  if (errors) {
    return errors.map(error => error.message);
  }
  return [];
};
