import React from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const getTiming = () => Math.round(Date.now());

// Channel is a MessageChannel channel
export const sendMessage = (
  message: any,
  origin: string,
  channel?: MessagePort | null,
) => {
  if (channel) {
    channel.postMessage(message);
  } else {
    window.parent.postMessage(message, origin);
  }
};

export const parseToken = (token: string) => {
  const json = decodeURI(token);
  const decodedJson = window.atob(json);
  return JSON.parse(decodedJson);
};
