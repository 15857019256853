import React, { useCallback, useState } from 'react';
import * as util from '../util.tsx';
import useWebSocket from '../Socket';
import { calcStyle } from './model';

const ERROR_TYPE = 'error';
const CHECKOUT_SESSION = 'host:hostedCheckout';
const CHECKOUT_SESSION_TYPE = 'hosted_checkout';
const Button = () => {
  const query = util.useQuery();
  const token = query.get('token');
  const json = decodeURI(token);
  const decodedJson = window.atob(json);
  const tokenObject = JSON.parse(decodedJson);
  const origin = tokenObject.origin;

  const [isCoolingDown, setIsCoolingDown] = useState(false);
  const [sessionReady, setSessionReady] = useState(false);

  const [styles] = useState(calcStyle(tokenObject.style));

  const sendReadyMessage = useCallback(
    session => {
      util.sendMessage(
        {
          type: `pt-static:button-ready`,
          sessionId: session,
        },
        origin,
      );
    },
    [origin],
  );

  const sendClickMessage = useCallback(() => {
    // Don't do anything if the button is cooling down
    if (isCoolingDown) return;

    util.sendMessage(
      {
        type: `pt-static:button-click`,
      },
      origin,
    );

    // Set the button to cooling down
    setIsCoolingDown(true);
    setTimeout(() => {
      setIsCoolingDown(false);
    }, 1000);
  }, [origin]);

  const messageCallback = useCallback(message => {
    const data = JSON.parse(message.data);
    let body = data?.body;

    switch (data?.type) {
      case ERROR_TYPE:
        util.sendMessage(
          {
            type: `pt-static:error`,
            error: `SOCKET_ERROR: ${body}`,
            field: 'button',
          },
          origin,
        );
        break;
      case CHECKOUT_SESSION_TYPE:
        setSessionReady(true);
        sendReadyMessage(body.sessionId);
        break;
      default:
        util.sendMessage(
          {
            type: `pt-static:error`,
            error: 'SOCKET_ERROR: There was an error with the socket.',
            field: 'button',
          },
          origin,
        );
        break;
    }
  }, []);

  const openCallback = useCallback(websocket => {
    let body = {
      ptToken: tokenObject.token,
      origin,
      timing: util.getTiming(),
      checkoutDetails: tokenObject.checkoutDetails,
      style: tokenObject.style,
      checkoutType: 'button',
    };
    let message = {
      action: CHECKOUT_SESSION,
      encoded: window.btoa(JSON.stringify(body)),
    };
    websocket.send(JSON.stringify(message));
  }, []);

  const {} = useWebSocket({
    onOpen: openCallback,
    onMessage: messageCallback,
    onError: () => {
      util.sendMessage(
        {
          type: `pt-static:error`,
          error: 'SOCKET_ERROR: There was an error with the socket.',
          field: 'button',
        },
        origin,
      );
    },
    ptToken: tokenObject.token,
  });

  return (
    <React.Fragment>
      <button
        disabled={!sessionReady}
        id="pay-theory-checkout-button"
        onClick={sendClickMessage}
        type="button">
        {styles['alt-text'] && <span>{styles['alt-text']}</span>}
        <img alt="Pay Theory" src={styles.logo} />
      </button>
      <style global="true" jsx="true">
        {`
          #pay-theory-checkout-button {
            background-color: ${styles['background-color']};
            border-radius: ${styles['border-radius']};
            color: ${styles['font-color']};
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: ${styles['height']};
            width: 100%;
            border: none;
            outline: none;
            max-height: 55px;
            min-height: 30px;
            padding: 0 16px;
            border: ${styles['border']};
            overflow: visible;
            white-space: nowrap;
            min-width: ${styles['alt-text'] ? '230px' : '120px'};
          }

          #pay-theory-checkout-button:disabled {
            cursor: default;
          }

          #pay-theory-checkout-button span {
            font-size: 16px;
            margin-right: 4px;
            height: 24px;
            display: flex;
            align-items: flex-end;
            font-family: halyard-text, inter, arial, sans-serif;
            font-weight: 200;
            white-space: nowrap;
          }

          #pay-theory-checkout-button img {
            height: 24px;
            width: auto;
            padding-bottom: ${styles['alt-text'] ? '4px' : '0'};
          }
        `}
      </style>
    </React.Fragment>
  );
};

export default Button;
