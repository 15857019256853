// eslint-disable-file no-unused-vars
import React, {
  useCallback,
  // eslint-disable-next-line import/no-unresolved
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as util from '../util';
import * as network from '../HostedFields/network';
import * as encryption from '../HostedFields/encryption';
import { useWindowListener } from '../HostedFields/hooks';
import { BoxKeyPair } from 'tweetnacl';

// Types for message handling
// interface MessageEvent {
//   data: string;
//   origin: string;
//   source: Window;
// }
//
// interface RelayMessage {
//   type: string;
//   data?: any;
//   error?: string;
//   field?: string;
// }

interface HostTokenMessage {
  type: typeof HOST_TOKEN_TYPE;
  body: {
    hostToken: string;
    publicKey: string;
    merchantUid: string;
    sessionKey: string;
  };
}

interface ErrorMessage {
  type: typeof ERROR_TYPE;
  body: string;
}

interface WalletFees {
  credit: number;
  debit: number;
}

interface AppleMerchantValidationMessage {
  type: typeof APPLE_MERCHANT_VALIDATION_TYPE;
  body: {
    apple_pay_session_obj: {
      epochTimestamp: number;
      expiresAt: number;
      merchantSessionIdentifier: string;
      nonce: string;
      merchantIdentifier: string;
      domainName: string;
      displayName: string;
      signature: string;
      operationalAnalyticsIdentifier: string;
      retries: number;
      pspId: string;
    };
    fees?: WalletFees;
  };
}

interface ApplePayTransactMessage {
  type: typeof APPLE_PAY_TRANSACTION_TYPE;
  body: {
    token: string;
  };
}

type WebSocketMessage =
  | HostTokenMessage
  | ErrorMessage
  | AppleMerchantValidationMessage
  | ApplePayTransactMessage;

interface EncryptedMessage {
  action: string;
  sessionKey: string;
  encoded: Uint8Array;
  publicKey: string;
}

interface EncodedMessage {
  action: string;
  encoded: string;
}

interface PendingMessage {
  resolve: (_value: boolean) => void;
  reject: (_error: Error) => void;
  message: any;
  handler: (_message: any) => void;
  messagePort: MessagePort;
}

const getTiming = (): number => Math.round(Date.now());

// Outgoing message types
const HOST_TOKEN = 'host:hostToken';
const APPLE_MERCHANT_VALIDATION = 'host:apple_merchant_validation';
const APPLE_PAY_TRANSACTION = 'host:apple_pay_transaction';

// Incoming message types from the parent window
const STATIC_APPLE_MERCHANT_VALIDATION = 'pt-static:merchant_validation';
const STATIC_APPLE_PAY_TRANSACTION = 'pt-static:apple_pay_transaction';
const TOKENIZE_WALLET_PAYLOAD = 'pt-static:tokenize_wallet_payload';

// Messages Types being returned from the websocket
const HOST_TOKEN_TYPE = 'host_token';
const ERROR_TYPE = 'error';
const APPLE_MERCHANT_VALIDATION_TYPE = 'apple_merchant_validation';
const APPLE_PAY_TRANSACTION_TYPE = 'apple_pay_transaction';

const Relay: React.FC = () => {
  const query = util.useQuery();
  const token = query.get('token');
  const { origin, sessionKey, ptToken } = useMemo(() => {
    if (token) return util.parseToken(token);
    return { origin: '', sessionKey: '', ptToken: '' };
  }, [token]);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [hostToken, setHostToken] = useState<string | null>(null);
  const [publicKey, setPublicKey] = useState<Uint8Array | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [tokenTimeout, setTokenTimeout] = useState<false | number>(false);
  // Object to store messageChannel for communication with the parent window
  const messageChannel = useRef<{ channel: MessagePort | null }>({
    channel: null,
  });

  const keyPair = useRef<BoxKeyPair>(encryption.generateKeyPair());
  const boxed = useMemo<Uint8Array | null>(() => {
    if (publicKey)
      return encryption.pairedBox(publicKey, keyPair.current.secretKey);
    return null;
  }, [publicKey]);

  const pendingMessage = useRef<PendingMessage | null>(null);

  const sendMessageToParent = useCallback(
    (message: any) => {
      let message_channel = messageChannel.current.channel;
      if (pendingMessage.current) {
        message_channel = pendingMessage.current.messagePort;
      }
      util.sendMessage(message, origin, message_channel);
      messageChannel.current.channel = null;
    },
    [origin, pendingMessage.current, messageChannel.current],
  );

  // Handle messages from the websocket
  const messageCallback = useCallback(
    (message: MessageEvent<string>) => {
      const data: WebSocketMessage = JSON.parse(message.data);
      let body = data?.body;

      switch (data.type) {
        case ERROR_TYPE:
          if (pendingMessage.current) {
            sendMessageToParent({
              type: 'relay:error',
              error: `SOCKET_ERROR: ${body}`,
              field: 'relay',
            });

            pendingMessage.current.reject(new Error(`SOCKET_ERROR: ${body}`));
            pendingMessage.current = null;
          } else {
            sendMessageToParent({
              type: 'relay:error',
              error: `SOCKET_ERROR: ${body}`,
              field: 'relay',
            });
          }
          break;

        case HOST_TOKEN_TYPE:
          body = body as HostTokenMessage['body'];
          setHostToken(body.hostToken);
          let socketKey = encryption.decodeKey(body.publicKey);
          setPublicKey(socketKey);
          if (tokenTimeout) clearTimeout(tokenTimeout);
          const hostTokenTimeout = setTimeout(() => {
            if (websocket?.readyState === websocket?.OPEN && websocket) {
              websocket.close();
            }
          }, 14 * 60000);
          setTokenTimeout(hostTokenTimeout);

          if (pendingMessage.current) {
            try {
              pendingMessage.current.handler(pendingMessage.current.message);
              pendingMessage.current.resolve(true);
            } catch (error) {
              sendMessageToParent({
                type: 'relay:error',
                error: 'Handler execution failed',
                field: 'relay',
              });
              pendingMessage.current.reject(error as Error);
            }
            pendingMessage.current = null;
          }

          sendMessageToParent({
            type: `pt-static:connected`,
            element: 'relay',
          });
          break;

        case APPLE_MERCHANT_VALIDATION_TYPE:
          if (body) {
            body = body as AppleMerchantValidationMessage['body'];
            sendMessageToParent({
              type: STATIC_APPLE_MERCHANT_VALIDATION,
              body: {
                apple_pay_session_obj: body.apple_pay_session_obj,
                fees: body.fees,
              },
              field: 'relay',
            });
          }
          break;
        case APPLE_PAY_TRANSACTION_TYPE:
          if (body) {
            sendMessageToParent({
              type: STATIC_APPLE_PAY_TRANSACTION,
              body,
              field: 'relay',
            });
          }
          break;
        default:
          if (pendingMessage.current) {
            sendMessageToParent({
              type: 'relay:error',
              error: 'SOCKET_ERROR: Unknown message type',
              field: 'relay',
            });
          } else {
            sendMessageToParent({
              type: 'relay:error',
              error: 'SOCKET_ERROR: Unknown message type',
              field: 'relay',
            });
          }
          break;
      }
    },
    [origin, websocket, publicKey, tokenTimeout, pendingMessage.current],
  );

  //Checks to make sure we have everything needed to send to the socket and then sends it or adds it to a message backlog to be sent after reconnection
  const socketAction = useCallback(
    (action: string, encoded: Record<string, any>): void => {
      let message: null | EncodedMessage | EncryptedMessage = null;
      // Create the message based on the action type
      if (action === HOST_TOKEN) {
        message = {
          action,
          encoded: window.btoa(JSON.stringify(encoded)),
        };
      } else if (boxed) {
        message = {
          action,
          sessionKey: window.btoa(sessionKey),
          encoded: encryption.encrypt(boxed, encoded),
          publicKey: encryption.encodeKey(keyPair.current.publicKey),
        };
      }

      // Send the message if the websocket is open
      if (websocket && websocket.readyState === websocket.OPEN && message) {
        const payload = JSON.stringify(message);
        const sized = new Blob([payload]).size;
        if (sized >= 32000) {
          // Send an SOS if the message size exceeds 32k
          network.sendSOS(
            `SOCKET_ACTION_FAILED: ${sized} > 32k, ACTION: ${action}, SESSION: ${sessionKey}`,
          );
        }
        websocket.send(JSON.stringify(message));
      } else {
        // Send an error message if the socket is not open
        sendMessageToParent({
          type: `pt-static:error`,
          error: `SOCKET_ERROR: Unable to send message to socket. Socket is not open.`,
          field: 'relay',
        });
      }
    },
    [boxed, origin, keyPair, websocket],
  );

  const requestHostToken = useCallback(
    (token: string, origin: string) =>
      socketAction(HOST_TOKEN, {
        ptToken: token,
        origin,
        timing: getTiming(),
      }),
    [HOST_TOKEN, socketAction, sessionKey],
  );

  // Generic version of waitForHostToken
  const waitForHostToken = useCallback(
    (
      message: any,
      handler: (_message: any) => void,
      messagePort: MessagePort,
    ): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        messageChannel.current.channel = messagePort;
        if (hostToken) {
          try {
            handler(message);
            resolve(true);
          } catch (error) {
            reject(error);
          }
        } else {
          if (pendingMessage.current) {
            pendingMessage.current.reject(
              new Error('New message received before previous was processed'),
            );
          }

          pendingMessage.current = {
            resolve,
            reject,
            message,
            handler,
            messagePort,
          };
        }
      });
    },
    [hostToken, pendingMessage.current, messageChannel.current],
  );

  // Initialize the websocket connection
  useEffect(() => {
    if (websocket && ptToken && origin && isConnected === false) {
      const empty = () => {};
      const READY_ACTIONS = {
        0: () => {
          websocket.onopen = () => {
            requestHostToken(ptToken, origin);
          };
          websocket.onmessage = messageCallback;
          websocket.onclose = () => {
            // Reset values to allow reconnecting
            setHostToken(null);
            setIsConnected(false);
            sendMessageToParent({
              type: `pt-static:error`,
              error: 'SESSION_EXPIRED',
              field: 'relay',
            });
          };
          setIsConnected(true);
        },
        1: empty,
        2: empty,
        3: empty,
      };
      READY_ACTIONS[websocket?.readyState === 0 ? 0 : 1]();
    }
  }, [
    websocket,
    ptToken,
    requestHostToken,
    messageCallback,
    origin,
    isConnected,
  ]);

  const appleMerchantValidationTypeMessage = (message: any) =>
    typeof message.type === 'string' &&
    message.type === STATIC_APPLE_MERCHANT_VALIDATION;
  const applePayTransactionTypeMessage = (message: any) =>
    typeof message.type === 'string' &&
    message.type === STATIC_APPLE_PAY_TRANSACTION;
  const tokenizeWalletPayloadTypeMessage = (message: any) =>
    typeof message.type === 'string' &&
    message.type === TOKENIZE_WALLET_PAYLOAD;

  interface AppleMerchantValidation {
    displayName: string;
    merchantIdentifier: string;
    validationURL: string;
    calculateFees: boolean;
    ports: [MessagePort];
  }

  const appleMerchantValidationHandler = useCallback(
    async (message: AppleMerchantValidation) => {
      try {
        await waitForHostToken(
          message,
          (msg: AppleMerchantValidation) => {
            const encoded = {
              host_token: hostToken,
              timing: getTiming(),
              display_name: msg.displayName,
              initiative: 'web',
              initiative_context: origin.replace(/(^\w+:|^)\/\//, ''),
              merchant_identifier: msg.merchantIdentifier,
              validation_url: msg.validationURL,
              calculate_fees: msg.calculateFees,
            };
            socketAction(APPLE_MERCHANT_VALIDATION, encoded);
          },
          message.ports[0],
        );
      } catch (error) {
        sendMessageToParent({
          type: `pt-static:error`,
          error: 'VALIDATION_FAILED: Unable to validate merchant',
          field: 'relay',
        });
      }
    },
    [origin, hostToken, socketAction, waitForHostToken],
  );

  const applePayTransactionHandler = useCallback(
    (message: {
      apple_pay_payload: Record<string, any>;
      paytheory_payload: Record<string, any>;
    }) => {
      let encoded = {
        timing: getTiming(),
        apple_pay_payload: message.apple_pay_payload,
        paytheory_payload: message.paytheory_payload,
      };
      socketAction(APPLE_PAY_TRANSACTION, encoded);
    },
    [origin, socketAction],
  );

  const tokenizeWalletPayloadHandler = useCallback(
    async (message: { payload: any; ports: [MessagePort] }) => {
      try {
        const encryptedData = encryption.encrypt(boxed, message.payload);

        const encodedPayload = window.btoa(
          JSON.stringify({
            session_id: sessionKey,
            public_key: encryption.encodeKey(keyPair.current.publicKey),
            encrypted_data: encryptedData,
          }),
        );

        message.ports[0].postMessage({
          type: 'TOKENIZE_WALLET_RESPONSE',
          payload: encodedPayload,
        });
      } catch (error) {
        message.ports[0].postMessage({
          type: 'TOKENIZE_WALLET_ERROR',
          error: 'Failed to encrypt wallet payload',
        });
      }
    },
    [boxed, sessionKey, keyPair.current.publicKey],
  );

  useWindowListener(
    appleMerchantValidationTypeMessage,
    appleMerchantValidationHandler,
  );
  useWindowListener(applePayTransactionTypeMessage, applePayTransactionHandler);
  useWindowListener(
    tokenizeWalletPayloadTypeMessage,
    tokenizeWalletPayloadHandler,
  );

  // Create the websocket connection once the ptToken is available
  useEffect(() => {
    if (ptToken && !websocket) {
      const socket = network.createSocket(ptToken);
      setWebsocket(socket);
    }
  }, [ptToken]);

  // Close the websocket connection when the component unmounts
  useEffect(() => {
    return () => {
      if (websocket) {
        websocket.close();
      }
    };
  }, [websocket]);

  return <div className="relay-container" />;
};

export default Relay;
