import React, { useCallback, useState } from 'react';
import * as util from '../util.tsx';
import useWebSocket from '../Socket';
import { QRCodeCanvas } from 'qrcode.react';
import logo from '../Images/pay_theory_logomark_black.svg';

import * as encryption from '../HostedFields/encryption';

const ERROR_TYPE = 'error';
const CHECKOUT_SESSION = 'host:hostedCheckout';
const CHECKOUT_SESSION_TYPE = 'hosted_checkout';
const CHECKOUT_SESSION_SUCCESS_TYPE = 'hosted_checkout_success';
const CheckoutQR = () => {
  const query = util.useQuery();
  const token = query.get('token');
  const json = decodeURI(token);
  const decodedJson = window.atob(json);
  const tokenObject = JSON.parse(decodedJson);
  const origin = tokenObject.origin;
  const size = tokenObject.size;
  const logoSize = Math.floor(size / 5);

  const [session, setSession] = useState(null);
  const [keyPair, _setKeyPair] = useState(encryption.generateKeyPair());

  const messageCallback = useCallback(message => {
    const data = JSON.parse(message.data);
    let body = data?.body;

    switch (data?.type) {
      case ERROR_TYPE:
        util.sendMessage(
          {
            type: `pt-static:error`,
            error: `SOCKET_ERROR: ${body}`,
            field: 'button',
          },
          origin,
        );
        break;
      case CHECKOUT_SESSION_TYPE:
        setSession(body.sessionId);
        util.sendMessage({ type: `pt-static:qr-ready` }, origin);
        break;
      case CHECKOUT_SESSION_SUCCESS_TYPE:
        const messagePublicKey = encryption.decodeKey(data.public_key);
        const messageBox = encryption.pairedBox(
          messagePublicKey,
          keyPair.secretKey,
        );
        body = encryption.decrypt(messageBox, body);
        util.sendMessage(
          {
            type: `pt-static:qr-checkout-success`,
            data: body,
          },
          origin,
        );
        break;
      default:
        util.sendMessage(
          {
            type: `pt-static:error`,
            error: 'SOCKET_ERROR: There was an error with the socket.',
            field: 'button',
          },
          origin,
        );
        break;
    }
  }, []);

  const openCallback = useCallback(websocket => {
    let body = {
      ptToken: tokenObject.token,
      origin,
      publicKey: encryption.encodeKey(keyPair.publicKey),
      timing: util.getTiming(),
      checkoutDetails: tokenObject.checkoutDetails,
      style: tokenObject.style,
      checkoutType: 'qr_code',
    };
    let message = {
      action: CHECKOUT_SESSION,
      encoded: window.btoa(JSON.stringify(body)),
    };
    websocket.send(JSON.stringify(message));
  }, []);

  useWebSocket({
    onOpen: openCallback,
    onMessage: messageCallback,
    onError: () => {
      util.sendMessage(
        {
          type: `pt-static:error`,
          error: 'SOCKET_ERROR: There was an error with the socket.',
          field: 'button',
        },
        origin,
      );
    },
    ptToken: tokenObject.token,
  });

  return (
    <React.Fragment>
      {session && (
        <QRCodeCanvas
          className="pt-qr-code"
          id="hosted-checkout-qr"
          imageSettings={{
            src: logo,
            height: logoSize,
            width: logoSize,
            excavate: true,
          }}
          includeMargin={false}
          level="H"
          size={size}
          value={`https://${process.env.REACT_APP_PARTNER}.checkout.${process.env.REACT_APP_STAGE}.com/hosted?sessionId=${session}`}
        />
      )}
    </React.Fragment>
  );
};

export default CheckoutQR;
